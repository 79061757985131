import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.object.values";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import TimeScreeningse from '@/components/timeScreeningse';
import nxDataTabs from '@/components/nx-data-tabs/nx-data-tabs';
import { MyLoweList, getStructureListArr } from '@/api/user_restPwd/Managesubordinates/department';
export default {
  components: {
    Head: Head,
    TimeScreeningse: TimeScreeningse,
    nxDataTabs: nxDataTabs
  },
  data: function data() {
    return {
      titled: '趋势分析',
      value1: '',
      customer_level: '',
      customer: [],
      //学员级别
      bumen: '',
      bumenoptions: [],
      //部门
      easyDataOption: {
        style: 'width:20%',
        span: 4,
        data: [{
          title: 'hhh',
          count: 0,
          imgUrl: process.env.VUE_APP_BASE_API2 + '/storage/client/teacher/img/20210305/41d56b63a8b8d987f3efa213c6db8cd5.jpg',
          key: 'fa fa-line-chart'
        }, {
          title: '创建数',
          count: 0,
          imgUrl: process.env.VUE_APP_BASE_API2 + '/storage/client/teacher/img/20210305/12719e6e5da49b57f104a6617e2c4ece.jpg',
          key: 'fa fa-plus'
        }, {
          title: '跟进数',
          count: 0,
          imgUrl: process.env.VUE_APP_BASE_API2 + '/storage/client/teacher/img/20210305/0b3e934c68d28335018eadc4a787c098.jpg',
          key: 'fa fa-share-alt'
        }, {
          title: '报名成交数',
          count: 0,
          imgUrl: process.env.VUE_APP_BASE_API2 + '/storage/client/teacher/img/20210305/95cc469852f11a82cd67efcb67474bde.jpg',
          key: 'fa fa-random'
        }, {
          title: '回归共海数',
          count: 0,
          imgUrl: process.env.VUE_APP_BASE_API2 + '/storage/client/teacher/img/20210305/980d7f5dedaf85e86c6fa88febbd3870.jpg',
          key: 'fa fa-bicycle'
        }, {
          title: '分配数',
          count: 0,
          imgUrl: process.env.VUE_APP_BASE_API2 + '/storage/client/teacher/img/20210305/98155fc3bf345bf6870d95370ba02f4f.jpg',
          key: 'fa fa-subway'
        }, {
          title: '领取数',
          count: 0,
          imgUrl: process.env.VUE_APP_BASE_API2 + '/storage/client/teacher/img/20210305/2695e27252ac27f80cb55489b2bc160c.jpg',
          key: 'el-icon-edit'
        }, {
          title: '外送公海池数',
          count: 0,
          imgUrl: process.env.VUE_APP_BASE_API2 + '/storage/client/teacher/img/20210305/95cc469852f11a82cd67efcb67474bde.jpg',
          key: 'fa fa-car'
        }, {
          title: '私海总数据量',
          count: 0,
          imgUrl: process.env.VUE_APP_BASE_API2 + '/storage/client/teacher/img/20210305/0b3e934c68d28335018eadc4a787c098.jpg',
          key: 'fa fa-calendar-check-o'
        }, {
          title: '未回访',
          count: 0,
          imgUrl: process.env.VUE_APP_BASE_API2 + '/storage/client/teacher/img/20210305/2695e27252ac27f80cb55489b2bc160c.jpg',
          key: 'fa fa-diamond'
        }]
      },
      chartData: {
        columns: ['日期', '未回访总量', '被共享', '创建的'],
        rows: []
      },
      tableData: []
    };
  },
  methods: {
    GetList: function GetList() {
      var _this = this;
      MyLoweList({
        start_time: this.start_time,
        end_time: this.end_time,
        uid: this.uid
      }).then(function (respomse) {
        _this.tableData = respomse.data.Structure_arr;
        _this.easyDataOption.data.map(function (item, index) {
          item.count = Object.values(respomse.data.sum)[index];
        });
        _this.chartData.rows = respomse.data.statistics_obj_group.map(function (item, index) {
          return {
            日期: item.data_time,
            被共享: item.be_shared_num,
            创建的: item.create_num,
            未回访总量: item.untreated_num_sum
          };
        });
      });
    },
    screening: function screening() {},
    DataTime: function DataTime() {},
    handleChange: function handleChange() {}
  }
};